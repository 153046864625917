import {default as SPLM} from "@atlas/api-javascript-sdk/no/splm";
import "@atlas/api-javascript-sdk/no/index.js"
import "@atlas/api-javascript-sdk/AME/index.js"
import "@atlas/api-javascript-sdk/AnimationDesigner/index.js"
import "@atlas/api-javascript-sdk/Annotations/index.js"
import "@atlas/api-javascript-sdk/Assemblies/index.js"
import "@atlas/api-javascript-sdk/BodyDes/index.js"
import "@atlas/api-javascript-sdk/CAE/index.js"
import "@atlas/api-javascript-sdk/CAM/index.js"
import "@atlas/api-javascript-sdk/CLDKin/index.js"
import "@atlas/api-javascript-sdk/CapitalIntegration/index.js"
import "@atlas/api-javascript-sdk/CloudDM/index.js"
import "@atlas/api-javascript-sdk/CollaborationApplication/index.js"
import "@atlas/api-javascript-sdk/DesignSimulation/index.js"
import "@atlas/api-javascript-sdk/DMU/index.js"
import "@atlas/api-javascript-sdk/Diagramming/index.js"
import "@atlas/api-javascript-sdk/DiagrammingLibraryAuthor/index.js"
import "@atlas/api-javascript-sdk/Die/index.js"
import "@atlas/api-javascript-sdk/Display/index.js"
import "@atlas/api-javascript-sdk/Drafting/index.js"
import "@atlas/api-javascript-sdk/Drawings/index.js"
import "@atlas/api-javascript-sdk/ElectricalRouting/index.js"
import "@atlas/api-javascript-sdk/Facet/index.js"
import "@atlas/api-javascript-sdk/Features/index.js"
import "@atlas/api-javascript-sdk/Fields/index.js"
import "@atlas/api-javascript-sdk/Formboard/index.js"
import "@atlas/api-javascript-sdk/Gateway/index.js"
import "@atlas/api-javascript-sdk/GeometricAnalysis/index.js"
import "@atlas/api-javascript-sdk/GeometricUtilities/index.js"
import "@atlas/api-javascript-sdk/Issue/index.js"
import "@atlas/api-javascript-sdk/JamTestNamespace/index.js"
import "@atlas/api-javascript-sdk/Join/index.js"
import "@atlas/api-javascript-sdk/Layer/index.js"
import "@atlas/api-javascript-sdk/Layout2d/index.js"
import "@atlas/api-javascript-sdk/LineDesigner/index.js"
import "@atlas/api-javascript-sdk/MBD/index.js"
import "@atlas/api-javascript-sdk/MFGViewMaker/index.js"
import "@atlas/api-javascript-sdk/MPA/index.js"
import "@atlas/api-javascript-sdk/Markup/index.js"
import "@atlas/api-javascript-sdk/MechanicalRouting/index.js"
import "@atlas/api-javascript-sdk/Mechatronics/index.js"
import "@atlas/api-javascript-sdk/Mfg/index.js"
import "@atlas/api-javascript-sdk/MfgModel/index.js"
import "@atlas/api-javascript-sdk/ModlDirect/index.js"
import "@atlas/api-javascript-sdk/ModlUtils/index.js"
import "@atlas/api-javascript-sdk/Motion/index.js"
import "@atlas/api-javascript-sdk/Newapp/index.js"
import "@atlas/api-javascript-sdk/OpenXml/index.js"
import "@atlas/api-javascript-sdk/Optimization/index.js"
import "@atlas/api-javascript-sdk/Options/index.js"
import "@atlas/api-javascript-sdk/PDM/index.js"
import "@atlas/api-javascript-sdk/PID/index.js"
import "@atlas/api-javascript-sdk/PLAS/index.js"
import "@atlas/api-javascript-sdk/PartFamily/index.js"
import "@atlas/api-javascript-sdk/PcbExchange/index.js"
import "@atlas/api-javascript-sdk/PhysMat/index.js"
import "@atlas/api-javascript-sdk/Placement/index.js"
import "@atlas/api-javascript-sdk/Positioning/index.js"
import "@atlas/api-javascript-sdk/Preferences/index.js"
import "@atlas/api-javascript-sdk/PressLineSimulation/index.js"
import "@atlas/api-javascript-sdk/PressLineSimulationTest/index.js"
import "@atlas/api-javascript-sdk/Report/index.js"
import "@atlas/api-javascript-sdk/Routing/index.js"
import "@atlas/api-javascript-sdk/RoutingCommon/index.js"
import "@atlas/api-javascript-sdk/Rule/index.js"
import "@atlas/api-javascript-sdk/SIM/index.js"
import "@atlas/api-javascript-sdk/SheetMetal/index.js"
import "@atlas/api-javascript-sdk/ShipDesign/index.js"
import "@atlas/api-javascript-sdk/StructureDesign/index.js"
import "@atlas/api-javascript-sdk/TDP/index.js"
import "@atlas/api-javascript-sdk/ToolDesigner/index.js"
import "@atlas/api-javascript-sdk/Tooling/index.js"
import "@atlas/api-javascript-sdk/UserDefinedObjects/index.js"
import "@atlas/api-javascript-sdk/UserDefinedTemplate/index.js"
import "@atlas/api-javascript-sdk/Validate/index.js"
import "@atlas/api-javascript-sdk/VisualReporting/index.js"
import "@atlas/api-javascript-sdk/Weld/index.js"
export default SPLM

export { SdkObjectProxyHandler } from "@atlas/api-proxy-handler/src/proxyHandler.js";
export { RequestQueue } from "@atlas/request-queue/src/requestQueue.js";
